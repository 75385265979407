import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { SEO } from '../components/SEO';
import { Layout } from '../layouts/Layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Contributing" mdxType="SEO" />
    <h1>{`Contributing`}</h1>
    <h2>{`Organisation`}</h2>
    <p>{`We wanted to build our Design System based on this principle: `}<strong parentName="p">{`a Design System should be a collection of composable building blocks`}</strong>{`.`}</p>
    <p>{`In this spirit, the library exposes "macro" components, but also smaller "building blocks" used to produce these "macro" components.`}</p>
    <h3><inlineCode parentName="h3">{`./styles`}</inlineCode></h3>
    <p>{`This is where "basic" styled components live: the "building blocks". No JSX allowed, only small styled DOM elements. For instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export const MyLink = styled.a<{ type: 'primary' | 'secondary' }>\`...\`
`}</code></pre>
    <p>{`These styles represent the foundation of the Design System, and are exposed to enable consumers of the library to build variations of the macro components if needed.`}</p>
    <p>{`Macro components are composed of these styles mixed with other React elements.`}</p>
    <h4>{`💡 Example:`}</h4>
    <p>{`The base styles of the `}<inlineCode parentName="p">{`<Select>`}</inlineCode>{` component are exposed. This allows defining a "custom" select with some variation in style.`}</p>
    <h3><inlineCode parentName="h3">{`./components`}</inlineCode></h3>
    <p>{`This is where "macro" components live. Go nuts with JSX here!`}</p>
    <p>{`If you want or need to split your component into simpler ones, please export everything. This allows building variants with maximum reuse.`}</p>
    <p>{`If the component has complex logic, please try to abstract it away in a hook.`}</p>
    <h4>{`💡 Example:`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`<Select>`}</inlineCode>{` component is composed of a `}<inlineCode parentName="p">{`<SelectButton>`}</inlineCode>{` and a `}<inlineCode parentName="p">{`<SelectDrawer>`}</inlineCode>{`, and both are exposed.`}</p>
    <h3><inlineCode parentName="h3">{`./behaviours`}</inlineCode></h3>
    <p>{`This is where complex logic specific to a "class" of component lives. The idea again is to allow custom variation with maximum reuse.`}</p>
    <p>{`For example, the `}<inlineCode parentName="p">{`<Select>`}</inlineCode>{` behaviour is exposed through multiple hooks, allowing the user to build a custom component with the same behaviour.`}</p>
    <h3><inlineCode parentName="h3">{`./hooks`}</inlineCode></h3>
    <p>{`When a hook is not specific to some component, but is rather of general purpose (e.g. `}<inlineCode parentName="p">{`useLoseFocus`}</inlineCode>{`, `}<inlineCode parentName="p">{`useDebounce`}</inlineCode>{`...), this is where it goes.`}</p>
    <h3><inlineCode parentName="h3">{`./legacy`}</inlineCode></h3>
    <p>{`Hosts components from the previous DS organisation. They don't respect the coding style or current organisation. Use them only if there is no equivalent in `}<inlineCode parentName="p">{`./components`}</inlineCode>{` or `}<inlineCode parentName="p">{`./styles`}</inlineCode>{`, and don't make improvements (unless you have no other choice). Ideally they should be migrated first to the current organisation before any improvements.`}</p>
    <h3><inlineCode parentName="h3">{`./glyphs`}</inlineCode></h3>
    <p>{`Everything related to SVG Icons. Each `}<em parentName="p">{`glyph`}</em>{` is a different component exported in the namespace `}<inlineCode parentName="p">{`glpyhs`}</inlineCode>{`. So to use the glyph `}<inlineCode parentName="p">{`Heart`}</inlineCode>{`, you would use `}<inlineCode parentName="p">{`<glyphs.stroke.Heart>`}</inlineCode>{`.`}</p>
    <h2>{`Coding style`}</h2>
    <h3>{`TypeScript vs. JavaScript`}</h3>
    <p><strong parentName="p">{`Please always write in TypeScript when possible`}</strong>{`. That being said, the codebase will still accept JavaScript.`}</p>
    <h3>{`Imports`}</h3>
    <p>{`Try to import dependencies in this order:`}</p>
    <ol>
      <li parentName="ol">{`External dependencies (React, styled-components, ...)`}</li>
      <li parentName="ol">{`Internal dependencies (utils, other components, ...)`}</li>
      <li parentName="ol">{`Constants`}</li>
    </ol>
    <p>{`Please separate these categories with line breaks.`}</p>
    <p>{`Imports `}<strong parentName="p">{`SHALL`}</strong>{` be sorted alphabetical order.`}</p>
    <h3>{`Exports`}</h3>
    <h4>{`Named exports`}</h4>
    <p>{`Named exports are preferred over default exports:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Easier to follow`}</strong>{`: named export are imported with the same identifier. It is easier to reason on code with stable names.`}</li>
      <li parentName="ol"><strong parentName="li">{`Early errors`}</strong>{`: if you try to import something by name that is not there, an error will be thrown right of the bat. If you import a default that is not what you expect, you won't know until it blows up in some code path.`}</li>
      <li parentName="ol"><strong parentName="li">{`No thinking overload`}</strong>{`: no need for the importer to think of a name, the name is already set. The less thinking the better. You only have to think of a name if there is conflicts.`}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://humanwhocodes.com/blog/2019/01/stop-using-default-exports-javascript-module/"
      }}>{`Read more on the topic`}</a>{`.`}</p>
    <h4>{`Exporting components`}</h4>
    <p>{`To be able to truly benefit from `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{`, all Design System components should be `}<inlineCode parentName="p">{`styled`}</inlineCode>{` components, even if no styles are defined:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function BarComponent({ className, ... }) {
  return <Foo className={className} />
    ...
  </Foo>
}
export const Bar = styled(BarComponent)\`\`
`}</code></pre>
    <p>{`So please always wrap component exports in the `}<inlineCode parentName="p">{`styled()`}</inlineCode>{` HoC.`}</p>
    <h3>{`Code Documentation`}</h3>
    <p>{`Props documentation is generated with `}<a parentName="p" {...{
        "href": "https://github.com/reactjs/react-docgen"
      }}>{`react-docgen`}</a>{`. Thus, you `}<strong parentName="p">{`MUST define a type for the props of each component you will expose`}</strong>{` in the library.`}</p>
    <p>{`To ensure documentation is correctly generated and clear, please respect the following conventions:`}</p>
    <h4>{`Imported type for props`}</h4>
    <p><inlineCode parentName="p">{`react-docgen`}</inlineCode>{` can't handle imported types (be it PropTypes or plain types). But sometimes we need to `}<inlineCode parentName="p">{`extend`}</inlineCode>{` a component. When we do so, and in order to have a clear documentation, add a docblock specifying which component is extended.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ImportedComponentProps, ImportedComponent } from './distant/module'

type MyComponentProps = ImportedComponentProps & {
  foo: string
}

/** extends \`<ImportedComponent />, see there for additional props */
export function MyComponent({ foo, ...rest }: MyComponent) {
  return (
    <>
      ...
      <ImportedComponent {...rest} />
    </>
  )
}
`}</code></pre>
    <p>{`In this example, only `}<inlineCode parentName="p">{`foo`}</inlineCode>{` will be documented, but the docblock explains that additional properties can be added, and refers to the documentation of the extended component to have the full list of additional props.`}</p>
    <h4>{`Basic styled components`}</h4>
    <p><inlineCode parentName="p">{`react-docgen`}</inlineCode>{` can't handle basic styled-components natively. Relying on some conventions, it is possible to extend `}<inlineCode parentName="p">{`react-docgen`}</inlineCode>{` capabilities and handle some cases.`}</p>
    <p>{`For basic `}<inlineCode parentName="p">{`styled`}</inlineCode>{` components, you need to manually set the `}<inlineCode parentName="p">{`displayName`}</inlineCode>{`, because `}<inlineCode parentName="p">{`react-docgen`}</inlineCode>{` can't infer it for you. You also need to manually set the `}<inlineCode parentName="p">{`defaultProps`}</inlineCode>{` (even if you're already setting them through default arguments) because `}<inlineCode parentName="p">{`react-docgen`}</inlineCode>{` doesn't know how to extract them from the interpolations in the template.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import styled from 'styled-component'

export const MyStyledComponent = styled.div<{ display?: 'block' | 'inline' }>\`
  display: \${({ display = 'block' }) => display};
\`

MyStyledComponent.displayName = 'S.namespace.MyStyledComponent'
MyStyledComponent.defaultProps = {
  display: 'block',
}
`}</code></pre>
    <h4>{`Wrapped styled components`}</h4>
    <p><inlineCode parentName="p">{`react-docgen`}</inlineCode>{` can't handle wrapped `}<inlineCode parentName="p">{`styled`}</inlineCode>{` components natively. Again, we can extend `}<inlineCode parentName="p">{`react-docgen`}</inlineCode>{` capabilities and handle some cases with conventions. You need to manually set the `}<inlineCode parentName="p">{`displayName`}</inlineCode>{` for the same reasons, but `}<inlineCode parentName="p">{`react-docgen`}</inlineCode>{` will find `}<inlineCode parentName="p">{`defaultProps`}</inlineCode>{` by itself in the function signature. So, don't forget to add them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import styled from 'styled-component'

type MyComponentProps = {
  display?: 'block' | 'inline'
}

function MyComponent({ display = 'display' }) {
  //...
}

export const MyStyledComponent = styled(Component)\`\`
MyStyledComponent.displayName = 'MyStyledComponent'
`}</code></pre>
    <h2>{`Documentation`}</h2>
    <p>{`If you create a new component, create a new MDX file in the `}<inlineCode parentName="p">{`fronted/owl-nest/standalone/owl-kit-website/src/docs`}</inlineCode>{` folder. The file `}<strong parentName="p">{`MUST`}</strong>{` be named by the component `}<inlineCode parentName="p">{`displayName`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`💡Example:`}</strong>{` the file documenting the `}<inlineCode parentName="p">{`<S.image.Avatar />`}</inlineCode>{` component would be named `}<inlineCode parentName="p">{`S.image.Avatar.mdx`}</inlineCode>{`.`}</p>
    <p>{`You'll need to define some metadata at the beginning of the file:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`displayName`}</inlineCode>{`: the display name of component`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disabled`}</inlineCode>{`: set to `}<inlineCode parentName="li">{`true`}</inlineCode>{` if you don't want to give access to the page`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`flag`}</inlineCode>{`: if you want to flag some components (null to disable): e.g. legacy, experimental, deprecated.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`group`}</inlineCode>{`: the name of a component group used for display: e.g. Components, Crowdfunding, Glyphs, Layout, Styles.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`subgroup`}</inlineCode>{`: the name of a subgroup to further categorize components (optional)`}</li>
    </ul>
    <p>{`A few pre-built components are available to help composing documentation pages:`}</p>
    <ul>
      <li parentName="ul">{`ComponentInfo`}</li>
      <li parentName="ul">{`Playground`}</li>
      <li parentName="ul">{`PropsTable`}</li>
      <li parentName="ul">{`CodeBlock`}</li>
    </ul>
    <p>{`You `}<strong parentName="p">{`SHALL`}</strong>{` at least embed a `}<inlineCode parentName="p">{`Playground`}</inlineCode>{` and `}<inlineCode parentName="p">{`PropsTable`}</inlineCode>{` components. Furthermore, you `}<strong parentName="p">{`SHOULD`}</strong>{` showcase the component variants, if any.`}</p>
    <h3>{`Tip`}</h3>
    <p>{`ℹ️ Sometimes when adding a new page, Gatsby needs to be reloaded in order to take it into account.`}</p>
    <h1>{`Netlify`}</h1>
    <p>{`The website is (automatically) deployed on `}<a parentName="p" {...{
        "href": "https://app.netlify.com/sites/ulule-design-system/overview"
      }}>{`Netlify`}</a>{` from the `}<inlineCode parentName="p">{`master`}</inlineCode>{` branch.`}</p>
    <p>{`Netlify also offers automatic deployment for open PRs, so take a look at the GitHub checks section in your PR. 😉`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      